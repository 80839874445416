body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Merriweather, serif;
  font-weight: 900;
}
