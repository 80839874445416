.Toastify__toast {
  padding: 8px 20px;
}

.Toastify__toast-container--bottom-center {
  width: 532px;
  max-width: 100%;
}

.Toastify__toast--success {
  background-color: #bac914;
}

.Toastify__toast--error {
  background-color: #e50067;
}

.Toastify__close-button {
  align-self: center;
  margin-left: 20px;
}

.Toastify__close-button > svg {
  vertical-align: middle;
}
